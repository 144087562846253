.signature-name {
    label {
        font-size: 1.5em;
        margin: 0px;
    }
}

.signature-filed {
    max-width: 30rem;

    .SignatureImg {
        text-align: center;

        img {
            max-width: 100%;
            cursor: pointer;
            max-height: 5rem;
        }
    }

    .SignatureText {
        font-size: 1.5rem !important;
    }

    .card-body {
        // min-height: 200px;
    }
}

.sign-modal {
    height: 100%;

    .card {
        height: 100%;

        .card-header {
            padding: 5px;
            text-align: center;
            background-color: #d7d9e2;

            img {
                height: 25px;
            }

            .btn {
                background-color: transparent;
                border-color: transparent;
                padding: 0 10px;

                &.active {
                    border-bottom: 3px solid #2a3893;
                    color: #2a3893;
                    border-radius: 0;
                }
            }
        }
    }

    .SignatureTextDisplay {
        img {
            height: 2rem;
        }

        border-bottom: 2px solid #414141;
    }

    .SignatureImgBox {
        position: relative;

        #fileDropRef {
            display: none;
        }

        &.active {
            height: 100%;

            #fileDropRef {
                //opacity: 0;
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                cursor: pointer;
            }
        }

        .SignatureImg {
            height: 100%;
            text-align: center;

            img {
                max-height: 100%;
                max-width: 100%;
                cursor: pointer;
            }
        }
    }

    .SignaturePadBox {
        height: 100%;

        canvas {
            background-color: #e4e4e4;
        }
    }

    .signaturePadWrapper {
        position: relative;
        height: 100%;

        .btn-box {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        @media (orientation: portrait) {
            min-height: 250px;
        }
    }

    .add-img-btn {
        display: inline-block;
        background-color: #e4e4e4;
        border-radius: 500px;
        padding: 1rem;
    }
}

.sign-tools {
    position: absolute;
    bottom: 10px;
    left: 0px;

    .icon {
        display: block;
    }

    .active-icon {
        display: none;
    }

    .active {
        .icon {
            display: none;
        }

        .active-icon {
            display: block;
        }
    }
}

.modal-full-screen {
    @media (orientation: landscape) {
        .modal-dialog {
            align-items: stretch;
            max-width: 80%;

            /*
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 0; // */
        }
    }

}

.sign-modal-wrapper {
    .modal-dialog {
        // margin: 0.75rem auto;
    }

    .modal-content {
        border-radius: 6px 6px 12px 12px;
        overflow: hidden;
        border: none;
    }
}

.sign-modal {
    border-radius: 10px;

    .sign-footer {
        padding: 0;

        .btn {
            padding: 15px 0;
            border-radius: 0;
            border-left: none;
            border-color: #e4e4e4;
        }

        .row-btn {
            .col:first-child .btn {
                border-radius: 0 0 0 10px;
            }

            .col:last-child .btn {
                border-radius: 0 0 10px 0;
                border-right: none;
            }
        }
    }
}

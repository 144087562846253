.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

form.submitted .ng-touched.form-control.ng-invalid:not(form), form.submitted .ng-touched.ng-invalid .ng-select-container,
form.submitted .ng-untouched.form-control.ng-invalid:not(form),
form.submitted .ng-untouched.ng-invalid .ng-select-container,
.ng-touched.form-control.ng-invalid:not(form),
.ng-touched.ng-invalid .ng-select-container {
  border-color: #E02A40 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
form.submitted .ng-touched.ng-invalid ~ .invalid-feedback,
form.submitted .ng-untouched.ng-invalid ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}

form.submitted select.ng-touched.form-control.ng-invalid:not(form),
form.submitted select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-touched.form-control.ng-invalid:not(form) {
  background-position: right calc(0.375em + 0.7875rem) center;
}

.invalid-feedback.show {
  display: block;
}

.custom-form-style .form-control {
  background: #F2F2F2;
  border: 1px solid #8C9194;
  border-radius: 4px;
}
.custom-form-style .form-control:focus {
  border-color: #2A3893;
  background-color: #FFFFFF;
}
.custom-form-style .btn {
  border-radius: 5px;
}

label {
  font-weight: 500;
}

.bigger-radio input[type=radio] {
  transform: scale(1.5);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: auto;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.checked + .slider {
  background-color: #2A3893;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2A3893;
}

input:disabled.checked + .slider {
  background-color: rgba(42, 56, 147, 0.8);
}

input.checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-blue label {
  color: #2A3893;
}
.form-blue .form-control {
  border-color: #2A3893;
  border-width: 2px;
}

.calendar-icon {
  background-image: url("../assets/icon/calendar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.create-assign-form .col-form-label {
  min-width: 200px;
}

label.required:after {
  content: " *";
  color: #E02A40;
}

.form-control-primary {
  border: 2px solid #2A3893;
}

.my-assign-item .btn-primary.disabled, .my-assign-item .btn-primary:disabled {
  color: #fff;
  background-color: #868686;
  border-color: #868686;
}

.main-layout#wrapper {
  overflow-x: hidden;
  min-height: calc(100vh - 4px);
}
.main-layout #sidebar-wrapper {
  min-height: 100%;
  margin-left: -16rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
.main-layout #sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
}
.main-layout #sidebar-wrapper .list-group {
  width: 16rem;
}
.main-layout #page-content-wrapper {
  min-width: 100vw;
}
.main-layout#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
  min-height: 100%;
}
@media (min-width: 768px) {
  .main-layout #sidebar-wrapper {
    margin-left: 0;
  }
  .main-layout #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .main-layout#wrapper.toggled #sidebar-wrapper {
    margin-left: -16rem;
  }
}
.main-layout .logo-wide-box {
  transition: opacity 0.25s ease-in-out;
}
.main-layout .logo-wide-box {
  margin-left: 10px;
  visibility: visible;
  opacity: 1;
}
.main-layout.toggled .logo-wide-box {
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 768px) {
  .main-layout .logo-wide-box {
    visibility: hidden;
    opacity: 0;
  }
  .main-layout .hideSidebar .logo-wide-box {
    visibility: visible;
    opacity: 1;
  }
  .main-layout.toggled .logo-wide-box {
    margin-left: auto;
    visibility: visible;
    opacity: 1;
  }
}
.main-layout #sidebar-wrapper {
  z-index: 1;
}
.main-layout .main-sidebar-menu,
.main-layout .main-sidebar-menu-header {
  margin-top: 0.5rem;
  padding: 0 10px;
}
.main-layout .main-sidebar-menu .list-group-item,
.main-layout .main-sidebar-menu-header .list-group-item {
  color: #696969;
  font-weight: 500;
  border: none;
  line-height: 25px;
  background-color: #F4F4F4;
  border-radius: 5px;
}
.main-layout .main-sidebar-menu .menu-icon,
.main-layout .main-sidebar-menu-header .menu-icon {
  margin-right: 0.5rem;
}
.main-layout .main-sidebar-menu-header .list-group-item {
  color: #fff;
  background-color: #2A3893;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
.main-layout .main-sidebar-menu {
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  opacity: 1;
  transition: all 0.25s ease-in-out;
}
.main-layout .main-sidebar-menu.show {
  visibility: visible;
  overflow: hidden;
  max-height: 10000px;
  opacity: 1;
}
.main-layout .main-sidebar-menu .list-group-item:hover {
  color: #fff;
  background-color: #2A3893;
}
.main-layout .main-sidebar-menu .list-group-item:hover .menu-icon {
  filter: invert(1) grayscale(1) brightness(75);
}
.main-layout .main-sidebar-menu .active {
  color: #2A3893;
  background-color: #2A389326;
}

.fixed-video-layout {
  height: calc(100vh - 7px);
}
.fixed-video-layout .container-form {
  flex: 1 1 auto !important;
  overflow-y: scroll;
}

.inner-scroll-layout #sidebar-wrapper {
  height: calc(100vh - 4px);
  overflow-y: auto;
}
.inner-scroll-layout #page-content-wrapper {
  height: calc(100vh - 4px);
}
.inner-scroll-layout .scrollable {
  overflow-y: auto;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

form.submitted .ng-touched.form-control.ng-invalid:not(form), form.submitted .ng-touched.ng-invalid .ng-select-container,
form.submitted .ng-untouched.form-control.ng-invalid:not(form),
form.submitted .ng-untouched.ng-invalid .ng-select-container,
.ng-touched.form-control.ng-invalid:not(form),
.ng-touched.ng-invalid .ng-select-container {
  border-color: #E02A40 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
form.submitted .ng-touched.ng-invalid ~ .invalid-feedback,
form.submitted .ng-untouched.ng-invalid ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}

form.submitted select.ng-touched.form-control.ng-invalid:not(form),
form.submitted select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-touched.form-control.ng-invalid:not(form) {
  background-position: right calc(0.375em + 0.7875rem) center;
}

.invalid-feedback.show {
  display: block;
}

.custom-form-style .form-control {
  background: #F2F2F2;
  border: 1px solid #8C9194;
  border-radius: 4px;
}
.custom-form-style .form-control:focus {
  border-color: #2A3893;
  background-color: #FFFFFF;
}
.custom-form-style .btn {
  border-radius: 5px;
}

label {
  font-weight: 500;
}

.bigger-radio input[type=radio] {
  transform: scale(1.5);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: auto;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.checked + .slider {
  background-color: #2A3893;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2A3893;
}

input:disabled.checked + .slider {
  background-color: rgba(42, 56, 147, 0.8);
}

input.checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-blue label {
  color: #2A3893;
}
.form-blue .form-control {
  border-color: #2A3893;
  border-width: 2px;
}

.calendar-icon {
  background-image: url("../assets/icon/calendar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.create-assign-form .col-form-label {
  min-width: 200px;
}

label.required:after {
  content: " *";
  color: #E02A40;
}

.form-control-primary {
  border: 2px solid #2A3893;
}

.my-assign-item .btn-primary.disabled, .my-assign-item .btn-primary:disabled {
  color: #fff;
  background-color: #868686;
  border-color: #868686;
}

.call-layout#wrapper {
  overflow-x: hidden;
}
.call-layout #sidebar-wrapper {
  min-height: calc(100vh - 4px);
  margin-left: -16rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
.call-layout #sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
.call-layout #sidebar-wrapper .call-layout-sidebar {
  width: 16rem;
}
.call-layout #page-content-wrapper {
  min-width: 100vw;
}
.call-layout#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
@media (min-width: 768px) {
  .call-layout #sidebar-wrapper {
    margin-left: 0;
  }
  .call-layout #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .call-layout#wrapper.toggled #sidebar-wrapper {
    margin-left: -16rem;
  }
}
.call-layout .logo-wide-box {
  transition: opacity 0.25s ease-in-out;
}
.call-layout .logo-wide-box {
  margin-left: 10px;
  visibility: visible;
  opacity: 1;
}
.call-layout #sidebar-wrapper {
  z-index: 1;
  border-right: 2px solid #d0d0d0;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

form.submitted .ng-touched.form-control.ng-invalid:not(form), form.submitted .ng-touched.ng-invalid .ng-select-container,
form.submitted .ng-untouched.form-control.ng-invalid:not(form),
form.submitted .ng-untouched.ng-invalid .ng-select-container,
.ng-touched.form-control.ng-invalid:not(form),
.ng-touched.ng-invalid .ng-select-container {
  border-color: #E02A40 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
form.submitted .ng-touched.ng-invalid ~ .invalid-feedback,
form.submitted .ng-untouched.ng-invalid ~ .invalid-feedback,
.ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}

form.submitted select.ng-touched.form-control.ng-invalid:not(form),
form.submitted select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-untouched.form-control.ng-invalid:not(form),
select.ng-touched.form-control.ng-invalid:not(form) {
  background-position: right calc(0.375em + 0.7875rem) center;
}

.invalid-feedback.show {
  display: block;
}

.custom-form-style .form-control {
  background: #F2F2F2;
  border: 1px solid #8C9194;
  border-radius: 4px;
}
.custom-form-style .form-control:focus {
  border-color: #2A3893;
  background-color: #FFFFFF;
}
.custom-form-style .btn {
  border-radius: 5px;
}

label {
  font-weight: 500;
}

.bigger-radio input[type=radio] {
  transform: scale(1.5);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: auto;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.checked + .slider {
  background-color: #2A3893;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2A3893;
}

input:disabled.checked + .slider {
  background-color: rgba(42, 56, 147, 0.8);
}

input.checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-blue label {
  color: #2A3893;
}
.form-blue .form-control {
  border-color: #2A3893;
  border-width: 2px;
}

.calendar-icon {
  background-image: url("../assets/icon/calendar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.create-assign-form .col-form-label {
  min-width: 200px;
}

label.required:after {
  content: " *";
  color: #E02A40;
}

.form-control-primary {
  border: 2px solid #2A3893;
}

.my-assign-item .btn-primary.disabled, .my-assign-item .btn-primary:disabled {
  color: #fff;
  background-color: #868686;
  border-color: #868686;
}

.otp-input {
  width: 2.5em;
  text-align: center;
  margin-right: 0.5em;
  padding: 0 !important;
}
.otp-input.is-invalid {
  background-image: none;
  padding-right: 0.75rem;
}
@media (max-width: 991px) {
  .otp-input {
    width: 2em;
  }
}
@media (max-width: 767px) {
  .otp-input {
    width: 1.4em;
  }
}

.otp-form-group {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: baseline;
}

.upload-user-photo-wrapper {
  width: 135px;
  height: 135px;
  background: #ffffff;
  background-image: url("../assets/icon/user.svg");
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 6px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 300px;
  position: relative;
  border: 2px solid #fff;
}
.upload-user-photo-wrapper .camera-icon {
  background-image: url("../assets/icon/camera2.svg");
  position: absolute;
  bottom: 0;
  right: 0;
}
.upload-user-photo-wrapper #fileDropRef {
  display: block;
  position: absolute;
  background-color: #e4e4e4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.upload-user-photo-wrapper #fileDropRef:hover {
  cursor: pointer;
}
.upload-user-photo-wrapper .upload-user-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 500px;
}

.upload-user-photo-field-group {
  margin-top: -120px;
}
.upload-user-photo-field-group .upload-user-photo-field {
  margin-left: auto;
}
@media (max-width: 767px) {
  .upload-user-photo-field-group {
    margin-top: 0;
  }
  .upload-user-photo-field-group .upload-user-photo-field {
    margin-left: auto;
    margin-right: auto;
  }
}

.VideoWrapper {
  position: relative;
  display: none;
  background-color: #1f1f1f;
}
.VideoWrapper.callStarted {
  display: block;
  height: 100%;
}
.VideoWrapper .Video-control-top {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 9;
}
.VideoWrapper .Video-control-bottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index: 9;
}
.VideoWrapper .Video-control {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoWrapper .Video-control .btn-control {
  color: #4e4e4e;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  min-width: 40px;
  height: 40px;
  text-align: center;
  padding: 0 10px;
  line-height: 40px;
  margin: 0 5px;
}
.VideoWrapper .Video-control .btn-control:hover {
  background-color: #e4e4e4;
}
.VideoWrapper .Video-control .btn img {
  display: block;
}
.VideoWrapper .Video-control .btn-phone {
  margin: 0 5px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
}
.VideoWrapper .Video-control {
  opacity: 0.35;
  transition: opacity 0.15s ease-out;
}
.VideoWrapper:hover .Video-control {
  opacity: 1;
}
.VideoWrapper .VideoBoxWrapper {
  position: relative;
  background-color: #1f1f1f;
  justify-content: center;
}
.VideoWrapper.overlayMode .VideoBox {
  background-color: rgba(228, 228, 228, 0.21);
}
.VideoWrapper.overlayMode .video {
  line-height: 0;
  display: block;
  max-width: 100%;
  padding: 5px;
  width: auto;
}
.VideoWrapper.overlayMode #myVideoBox {
  background-color: rgba(177, 177, 177, 0.4);
}
.VideoWrapper.overlayMode.not-swap #yourVideoBox .video {
  height: 100%;
}
.VideoWrapper.overlayMode.not-swap #myVideoBox {
  position: absolute;
  min-width: 160px;
  width: 15%;
  height: auto;
  right: 10px;
  top: 10px;
}
.VideoWrapper.overlayMode.not-swap #myVideoBox .video {
  width: 100%;
}
.VideoWrapper.overlayMode.swap #myVideoBox .video {
  height: 100%;
}
.VideoWrapper.overlayMode.swap #yourVideoBox {
  position: absolute;
  min-width: 160px;
  width: 15%;
  height: auto;
  right: 10px;
  top: 10px;
}
.VideoWrapper.overlayMode.swap #yourVideoBox .video {
  width: 100%;
}
.VideoWrapper.splitMode .video {
  height: 100%;
}
.VideoWrapper.splitMode .VideoBox {
  background-color: rgba(228, 228, 228, 0.21);
  height: 100%;
}
.VideoWrapper.splitMode .video {
  line-height: 0;
  display: block;
  max-width: 100%;
  padding: 5px;
  width: auto;
}
.VideoWrapper.splitMode #yourVideoBox {
  max-width: 75%;
}
.VideoWrapper.splitMode #myVideoBox {
  background-color: rgba(177, 177, 177, 0.4);
  max-width: 25%;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper {
  flex-direction: row-reverse;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper #yourVideoBox {
  max-width: 25%;
}
.VideoWrapper.splitMode.swap .VideoBoxWrapper #myVideoBox {
  background-color: #b1b1b1;
  max-width: 75%;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
  border-radius: 5px;
}

.btn-warning,
.btn-secondary {
  color: #fff !important;
}

.btn-warning, .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: #fff;
}

.btn-outline-warning:hover, .btn-outline-warning:active {
  color: #fff !important;
}

.btn-round {
  border-radius: 50px;
  min-width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.alert-modal .modal-content {
  border-radius: 15px;
}

.custom-alert .modal-header {
  background-color: #2A3893;
  border-color: #2A3893;
  color: #FFFFFF;
}
.custom-alert .btn-link {
  color: #FFFFFF;
}
.custom-alert .modal-content {
  border-radius: 15px;
  border: none;
}

.alert-modal-header {
  border-color: #C5C5C5;
  font-size: 18px;
  font-weight: 700;
  color: #2A3893;
}

.alert-modal-dark {
  background-color: #2A3893;
  border-color: #2A3893;
  border-width: 2px;
  margin-top: -2px;
  margin-left: -2px;
  color: #FFFFFF;
}
.alert-modal-dark .btn-link {
  color: #FFFFFF;
}

.alert-content-wrapper {
  margin-top: -1.5rem;
}

.alert-icon {
  width: 75px;
  height: auto;
  margin-bottom: 1rem;
}

.alert-content {
  margin-bottom: 0.5rem;
}

.alert-title {
  font-size: 22px;
  font-weight: 600;
}

.alert-body {
  font-size: 18px;
  font-weight: 400;
}

.modal-button .btn {
  min-width: 100px;
}

.signature-name label {
  font-size: 1.5em;
  margin: 0px;
}

.signature-filed {
  max-width: 30rem;
}
.signature-filed .SignatureImg {
  text-align: center;
}
.signature-filed .SignatureImg img {
  max-width: 100%;
  cursor: pointer;
  max-height: 5rem;
}
.signature-filed .SignatureText {
  font-size: 1.5rem !important;
}
.sign-modal {
  height: 100%;
}
.sign-modal .card {
  height: 100%;
}
.sign-modal .card .card-header {
  padding: 5px;
  text-align: center;
  background-color: #d7d9e2;
}
.sign-modal .card .card-header img {
  height: 25px;
}
.sign-modal .card .card-header .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0 10px;
}
.sign-modal .card .card-header .btn.active {
  border-bottom: 3px solid #2a3893;
  color: #2a3893;
  border-radius: 0;
}
.sign-modal .SignatureTextDisplay {
  border-bottom: 2px solid #414141;
}
.sign-modal .SignatureTextDisplay img {
  height: 2rem;
}
.sign-modal .SignatureImgBox {
  position: relative;
}
.sign-modal .SignatureImgBox #fileDropRef {
  display: none;
}
.sign-modal .SignatureImgBox.active {
  height: 100%;
}
.sign-modal .SignatureImgBox.active #fileDropRef {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.sign-modal .SignatureImgBox .SignatureImg {
  height: 100%;
  text-align: center;
}
.sign-modal .SignatureImgBox .SignatureImg img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}
.sign-modal .SignaturePadBox {
  height: 100%;
}
.sign-modal .SignaturePadBox canvas {
  background-color: #e4e4e4;
}
.sign-modal .signaturePadWrapper {
  position: relative;
  height: 100%;
}
.sign-modal .signaturePadWrapper .btn-box {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (orientation: portrait) {
  .sign-modal .signaturePadWrapper {
    min-height: 250px;
  }
}
.sign-modal .add-img-btn {
  display: inline-block;
  background-color: #e4e4e4;
  border-radius: 500px;
  padding: 1rem;
}

.sign-tools {
  position: absolute;
  bottom: 10px;
  left: 0px;
}
.sign-tools .icon {
  display: block;
}
.sign-tools .active-icon {
  display: none;
}
.sign-tools .active .icon {
  display: none;
}
.sign-tools .active .active-icon {
  display: block;
}

@media (orientation: landscape) {
  .modal-full-screen .modal-dialog {
    align-items: stretch;
    max-width: 80%;
    /*
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 0; // */
  }
}

.sign-modal-wrapper .modal-content {
  border-radius: 6px 6px 12px 12px;
  overflow: hidden;
  border: none;
}

.sign-modal {
  border-radius: 10px;
}
.sign-modal .sign-footer {
  padding: 0;
}
.sign-modal .sign-footer .btn {
  padding: 15px 0;
  border-radius: 0;
  border-left: none;
  border-color: #e4e4e4;
}
.sign-modal .sign-footer .row-btn .col:first-child .btn {
  border-radius: 0 0 0 10px;
}
.sign-modal .sign-footer .row-btn .col:last-child .btn {
  border-radius: 0 0 10px 0;
  border-right: none;
}

#fabricSurface {
  width: 100%;
  height: 100%;
  background-color: #e4e4e4;
}

#fabricImageEditor.mode-pan .upper-canvas {
  cursor: grab !important;
}
#fabricImageEditor.mode-DrawRect .upper-canvas {
  cursor: crosshair !important;
}

#wrapper {
  border-top: 4px solid #2A3893;
}

.login-bg {
  background-image: url("../assets/img/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.noti-box {
  cursor: pointer;
  position: relative;
}
.noti-box .count-noti {
  top: -10px;
  right: 5px;
}

.count-noti {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  background-color: #E02A40;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  line-height: 21px;
  text-align: center;
  color: #fff;
}

.login-box {
  max-width: 450px;
}
.login-box .logo {
  width: 230px;
}
.login-box h1 {
  color: #2A3893;
  font-weight: bold;
  font-size: 30px;
}
.login-box .input-custom {
  background: #ffffff;
  border: 2px solid #2a3893;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.login-box .input-custom:-internal-autofill-selected {
  background-color: inherit !important;
  background-image: inherit !important;
}
.login-box .icon-username {
  background-image: url("../assets/icon/username.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 45px;
}
.login-box .icon-password {
  background-image: url("../assets/icon/password.svg");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding-left: 45px;
}

.user-menu-box {
  font-size: 18px;
  font-weight: 500;
}
.user-menu-box .dropdown-toggle:hover {
  text-decoration: none;
}
.user-menu-box .dropdown-toggle:after {
  display: none;
}

.menu-card-item {
  cursor: pointer;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 1.5rem 0rem;
  margin: 1rem auto;
  width: 230px;
}
.menu-card-item .menu-card-img {
  height: 120px;
}
.menu-card-item .icon {
  font-size: 100px;
  line-height: 120px;
  color: #2A3893;
}
.menu-card-item:hover {
  background-color: #F4F4F4;
}
.menu-card-item .menu-text {
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
}

.home-bg {
  background-image: url("../assets/img/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  .home-bg {
    padding-bottom: 250px;
  }
}

.page-title {
  font-size: 1.6rem;
  color: #504e4e;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.page-sub-title {
  font-size: 1.4rem;
  color: #504e4e;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.btn-view {
  border-top: 3px solid #787878;
  border-radius: 0 0 5px 5px;
}
.btn-view.btn-success {
  border-top: 3px solid #1a630d;
}

.custom-table {
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 655px;
  overflow: auto;
}
.custom-table thead {
  background-color: #e1f3ff;
}
.custom-table thead th {
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.25);
}
.custom-table .table {
  margin-bottom: 0;
}

.custom-table-blue .table {
  border: 1px solid #e4e4e4;
}
.custom-table-blue .table thead th {
  background-color: #2a3893;
  border-color: #fff;
  border-width: 1px;
}

.sticky-head thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.sticky-head.custom-table th {
  background-color: #e1f3ff;
}

.sticky-col thead .first-col {
  z-index: 2;
}
.sticky-col .first-col {
  position: sticky;
  background-color: white;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  left: 0px;
  top: 0;
}

.bg-call {
  background-image: url("../assets/img/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
}

.call-form-box {
  max-width: 340px;
}

.service-form {
  margin-top: 1rem;
}
.service-form .nav-tabs .nav-link {
  border-radius: 13px 13px 0 0;
  color: #fff;
  font-weight: 600;
  background-color: #2A3893;
  padding: 5px 30px;
}
.service-form .tab-content-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
}
.service-form.viewMode input[type=radio]:disabled {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 0px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: white;
  border-radius: 50%;
}
.service-form.viewMode input[type=radio]:checked {
  background-color: #525252;
}
.service-form.viewMode .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #525252;
}

.repair-sum {
  border: 1px solid #8c9194;
  padding: 1rem;
}

.disabled-gray:disabled {
  background-color: #8c9194;
  border-color: #8c9194;
}

.btn.btn-blue {
  background-color: #012f8a;
  border-color: #012f8a;
  color: #ffffff;
}
.btn.btn-blue:disabled {
  background-color: #868686;
  border-color: #868686;
}
.btn.btn-blue:hover, .btn.btn-blue:active, .btn.btn-blue:focus {
  background-color: #011e57 !important;
  border-color: #011e57 !important;
}

.btn-orange {
  background-color: #fa6b2e;
  border-color: #fa6b2e;
}
.btn-orange:hover, .btn-orange:active, .btn-orange:focus {
  background-color: #ef4c06 !important;
  border-color: #ef4c06 !important;
}

.btn-purple {
  background-color: #4d61e1;
  border-color: #4d61e1;
}
.btn-purple:hover, .btn-purple:active, .btn-purple:focus {
  background-color: #243cd7 !important;
  border-color: #243cd7 !important;
}

.btn.btn-yellow {
  background-color: #ffcb14;
  border-color: #ffcb14;
  color: #ffffff;
}
.btn.btn-yellow:disabled {
  background-color: #868686;
  border-color: #868686;
}
.btn.btn-yellow:hover, .btn.btn-yellow:active, .btn.btn-yellow:focus {
  background-color: #e0ae00 !important;
  border-color: #e0ae00 !important;
}

.btn.btn-green {
  background-color: #0eb03b;
  border-color: #0eb03b;
  color: #ffffff;
}
.btn.btn-green:disabled {
  background-color: #868686;
  border-color: #868686;
}
.btn.btn-green:hover, .btn.btn-green:active, .btn.btn-green:focus {
  background-color: #0a812b !important;
  border-color: #0a812b !important;
}

.capture-modal .col-form-label {
  width: 150px;
  text-align: right;
}

.CarImagesHead {
  background-color: #2a3893;
  color: #fff;
  padding: 5px;
  font-weight: 600;
}

.CarImageBox {
  padding: 0.5rem;
  position: relative;
}
.CarImageBox .CarImage {
  width: 100%;
  height: auto;
}

.CarImageBoxRepair {
  background-color: #c4c4c4;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.CarImageTools {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.3;
}
.CarImageTools .btn {
  margin-left: 3px;
}
.CarImageBox:hover .CarImageTools {
  opacity: 1;
}

.otp-input {
  border: 2px solid #c1c5ca;
}

.container-border {
  background: #ffffff;
  border: 2px solid #2a3893;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
}

.label-hide {
  margin-left: -9999px;
}

.resareas-box {
  background: #ffffff;
  border: 1px solid #8c9194;
  border-radius: 5px;
  padding: 0.5rem;
}

.resarea-item {
  margin-bottom: 5px;
}

.resarea-item-inner {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #5b69c7;
  border-radius: 5px;
  padding: 3px 10px;
  margin-right: 5px;
}

.custom-radio-inline .custom-radio {
  display: inline-block;
  margin-right: 1rem;
}

.btn-width {
  min-width: 100px;
}

.btn-history {
  background-color: #a424d1;
}
.btn-history:hover {
  background-color: #750c9a;
}

.part-price.ng-invalid {
  border-color: #E02A40;
}

.check-regis-detail-box .label {
  color: #2A3893;
  font-weight: 500;
}
.check-regis-detail-box .detail {
  padding: 5px;
  background: rgba(42, 56, 147, 0.23);
  border-radius: 5px;
  margin-bottom: 1rem;
}

.my-assign-detail-box .label {
  color: #2A3893;
  font-weight: 500;
}
.my-assign-detail-box .detail {
  background: #ffffff;
  border: 2px solid #2a3893;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.appointment-calendar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
}
.appointment-calendar ngb-datepicker {
  border: none;
  width: 100%;
}
.appointment-calendar .ngb-dp-month {
  width: 100%;
}
.appointment-calendar .ngb-dp-header {
  background: #e1f3ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 0;
}
.appointment-calendar .ngb-dp-weekdays {
  background-color: transparent;
  border: none;
}
.appointment-calendar .ngb-dp-weekday {
  width: 14.28%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: #2A3893;
}
.appointment-calendar .ngb-dp-day {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #2A3893;
  width: 14.28%;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  position: relative;
  margin-bottom: 15px;
}
.appointment-calendar .day-number {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  margin: 0.5rem auto;
  background-color: #f3f2f2;
  border-radius: 50px;
}
.appointment-calendar .outside-month {
  color: #9a9a9a;
  background-color: transparent;
  border-radius: 50px;
}
.appointment-calendar .ngb-dp-today .day-number {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  margin: 0.5rem auto;
  border: 2px solid #2A3893;
  border-radius: 50px;
}
.appointment-calendar .day-selected {
  background-color: #2A3893;
  color: #ffffff;
}
.appointment-calendar .day-close {
  color: #E02A40;
}
.appointment-calendar .count-appointment {
  width: auto;
  height: auto;
  line-height: 1rem;
  position: absolute;
  color: #ffffff;
  background-color: #E02A40;
  padding: 1px 5px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  left: 50%;
  bottom: 3px;
  min-width: 18px;
}

.contact-detail {
  padding: 1rem;
  background: #e1f3ff;
}
.contact-detail .contact-detail-head {
  color: #2A3893;
  font-size: 18px;
  text-align: center;
  border-bottom: #2A3893 solid 2px;
  width: fit-content;
  padding: 0 10px;
  margin: auto;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.contact-detail .contact-detail-subhead {
  color: #2A3893;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}
.contact-detail label {
  color: #2A3893;
  font-size: 14px;
}
.contact-detail .form-control {
  background-color: #ffffff;
  border: 1px solid #2a3893;
  border-radius: 5px;
  border-width: 1.5px;
}

.calendar-data-item {
  font-size: 14px;
  padding-top: 5px;
  border-top: 1px dashed #8c9194;
}

.bg-blue {
  background-color: #e1f3ff;
  padding-top: 10px;
  margin-bottom: 10px;
}

.repairpartgroup-table th {
  color: #fff;
  background-color: #2a3893;
}
.repairpartgroup-table th.phasename {
  font-size: 36px;
  font-weight: 600;
  width: 160px;
  max-width: 160px;
}
.repairpartgroup-table .repairpart_check {
  transform: scale(1.5);
}

.my-assign-card {
  border: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.my-assign-card .card-header {
  border: 0;
  background: #e1f3ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.my-assign-card .my-assign-label {
  min-width: 120px;
}

.my-assign-claim .page-title {
  font-size: 18px;
  color: #2a3893;
}
.my-assign-claim .page-sub-title {
  font-size: 16px;
  font-weight: 700;
}
.my-assign-claim .form-group {
  margin-bottom: 0.5rem;
}
.my-assign-claim .page-content {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.my-assign-claim .page-number {
  margin-bottom: 15px;
}
.my-assign-claim .page-number .btn {
  border-radius: 0;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  padding: 0;
}
.my-assign-claim .page-number .btn.disabled, .my-assign-claim .page-number .btn:disabled {
  opacity: 1 !important;
}
.my-assign-claim .page-header {
  border-bottom: 3px solid #2a3893;
  padding: 1rem 0;
}
.my-assign-claim .page-header .page-title {
  margin: 0;
}
.my-assign-claim .CarImageBox {
  max-width: 250px;
}
.my-assign-claim .CarImageBox .CarImageTools {
  opacity: 1;
}

.select-form-page .main-body {
  max-width: 576px;
  margin-left: auto;
  margin-right: auto;
}
.select-form-page .page-title {
  font-size: 32px;
  text-align: center;
  color: #2a3893;
}

.claim-hard-images-tab {
  border-bottom: 3px solid #2a3893;
}
.claim-hard-images-tab .nav-item {
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}
.claim-hard-images-tab .nav-link {
  background: #cacaca;
  border: none;
  border-radius: 5px 5px 0px 0px;
}
.claim-hard-images-tab .nav-link.active {
  background: #2a3893;
  border: none;
  border-radius: 5px 5px 0px 0px;
  color: #fff;
}

.btn-capture {
  color: #fff;
  background: rgba(52, 109, 253, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.claim-hard-images-capture {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.claim-hard-form-page .page-title {
  text-align: center;
}
.claim-hard-form-page.confirm-mode .btn-image-tab {
  border-bottom: 3px solid #2A3893;
  margin-bottom: 1rem;
}
.claim-hard-form-page.confirm-mode .btn-image-tab .btn {
  border-color: #cacaca;
  background-color: #cacaca;
  border-radius: 5px 5px 0 0;
}
.claim-hard-form-page.confirm-mode .btn-image-tab .btn.focus, .claim-hard-form-page.confirm-mode .btn-image-tab .btn:focus {
  color: #ffffff;
  background-color: #2A3893;
  border-color: #2A3893;
}

.btn-upload-document {
  padding: 15px 0;
  background: #ffffff;
  border: 2px dashed #2a3893;
  border-radius: 5px;
  position: relative;
}

.input-upload-document {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.claim-hard-document .upload-file-item {
  border-bottom: 1px dashed #2a3893;
}

.upload-file-item:last-child {
  border-bottom: none !important;
}

.claim-hard-table-body {
  border-top: 3px solid #2a3893;
}

.claim-hard-sum-cost {
  background: #ffffff;
  border: 1px solid #2a3893;
}

.image-car-card {
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}
.image-car-card .card-img-top {
  background-color: #efefef;
}

.search-data-form {
  padding: 0.5rem 1rem 0;
  border-radius: 0.75rem;
  border: 2px solid #2A3893;
}
.search-data-form label {
  color: #2A3893;
}
.search-data-form .form-control {
  border-color: #2A3893;
}

.body-pdf {
  background-color: #e4e4e4;
}

.SignatureImg {
  max-width: 100%;
}

.StatusFlowId-1 {
  color: #000;
}

.StatusFlowId-2 {
  color: #fc9a08;
}

.StatusFlowId-8 {
  color: #fc9a08;
}

.StatusFlowId-3 {
  color: #f54646;
}

.StatusFlowId-4 {
  color: #f54646;
}

.StatusFlowId-5 {
  color: #356cf9;
}

.admin-calendar .admin-calendar-detail {
  padding: 1rem;
  background: #e1f3ff;
  min-width: 320px;
}
.admin-calendar .admin-calendar-detail .admin-calendar-detail-head {
  color: #2A3893;
  font-size: 18px;
  text-align: center;
  border-bottom: #2A3893 solid 2px;
  width: fit-content;
  padding: 0 10px;
  margin: auto;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.admin-calendar .admin-calendar-detail .admin-calendar-detail-subhead {
  color: #2A3893;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}

.modal-body-recorded-video .video-thumbnail {
  max-width: 150px;
  height: auto;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #c5c5c5;
  box-shadow: 0px 0px 4px #c5c5c5;
}

.cover-title {
  margin: 15px;
  color: #ffffff;
  font-size: 34px;
}

.call-header.hideVideo {
  display: none;
}

.model-blue-border {
  border-radius: 8px;
  padding-top: 1rem;
  border: 1.5px solid #2A3893;
}

.noti-box-msg {
  padding: 0.5rem;
  width: 350px;
  max-width: 70vw;
}
.noti-box-msg .msg-item {
  border-radius: 3px;
  padding: 0.5rem;
  background-color: #e4eaef;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
}
.noti-box-msg .msg-item .col-icon {
  padding-right: 0.5rem;
}
.noti-box-msg .msg-item .icon {
  font-size: 1.5rem;
  color: #424b9a;
}
.noti-box-msg .msg-item:last-child {
  margin-bottom: 0px;
}

.line-control {
  padding: 3px;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  margin-bottom: 5px;
}
.line-control .btn-link {
  padding: 0;
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border-radius: 100px;
}
.line-control .btn-link.active {
  background-color: #e3e3e3;
}
.line-control .dot {
  margin: auto;
  display: block;
  background-color: #313131;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  padding: 0px;
}
.line-control .line-colors .dot {
  width: 21px;
  height: 21px;
}
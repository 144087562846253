#fabricSurface {
    width: 100%;
    height: 100%;

    background-color: #e4e4e4;
}

#fabricImageEditor {
    &.mode-pan {
        .upper-canvas {
            cursor: grab !important;
        }
    }

    &.mode-DrawRect {
        .upper-canvas {
            cursor: crosshair !important;
        }
    }
}

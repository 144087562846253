@import "styles.variables";
@import "~bootstrap/scss/functions";

.alert-modal {
    .modal-content {
        //border: none;
        border-radius: 15px;
    }


}

.custom-alert {

    .modal-header {
        background-color: $primary;
        border-color: $primary;
        color: #FFFFFF;
    }

    .btn-link {
        color: #FFFFFF;
    }

    .modal-content {
        //border: none;
        border-radius: 15px;
        border: none;
        //background-color: transparent;
    }
}

.alert-modal-header {
    border-color: #C5C5C5;
    font-size: 18px;
    font-weight: 700;
    color: $primary;
}

.alert-modal-dark {
    background-color: $primary;
    border-color: $primary;
    border-width: 2px;
    margin-top: -2px;
    margin-left: -2px;
    color: #FFFFFF;

    .btn-link {
        color: #FFFFFF;
    }
}


.alert-content-wrapper {
    margin-top: -1.5rem;
}

.alert-icon {
    width: 75px;
    height: auto;
    margin-bottom: 1rem;
}

.alert-content {
    margin-bottom: 0.5rem;
}

.alert-title {
    font-size: 22px;
    font-weight: 600;

}

.alert-body {
    font-size: 18px;
    font-weight: 400;

}

.modal-button {
    .btn {
        min-width: 100px;
    }
}


@import "styles.variables";
form.submitted .ng-touched,
form.submitted .ng-untouched,
.ng-touched {
    &.form-control.ng-invalid:not(form),
    &.ng-invalid .ng-select-container {
        border-color: #E02A40 !important;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E02A40' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E02A40' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    }

    &.ng-invalid ~ .invalid-feedback {
        display: block;
    }
}

form.submitted select.ng-touched ,
form.submitted select.ng-untouched ,
select.ng-untouched,
select.ng-touched {
    &.form-control.ng-invalid:not(form) {
        background-position: right calc(0.375em + 0.7875rem) center;
    }
}

.invalid-feedback.show {
    display: block;
}

.custom-form-style {
    .form-control {
        background: #F2F2F2;
        border: 1px solid #8C9194;
        border-radius: 4px;
    }

    .form-control:focus {
        border-color: $primary;
        background-color: #FFFFFF;
    }

    .btn {
        border-radius: 5px;
    }
}

label {
    font-weight: 500;
}

.bigger-radio {
    input[type='radio'] {
        transform: scale(1.5);
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin: auto;
    margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input.checked + .slider
{
    background-color: $primary;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primary;
}

input:disabled.checked + .slider
{
    background-color: rgba(42, 56, 147, 0.8);
}

input.checked + .slider:before,
{
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 30px;
}

.slider.round:before {
    border-radius: 50%;
}

.form-blue {
    label {
        color: $primary;
    }
    .form-control {
        border-color: $primary;
        border-width: 2px;
    }
}

.calendar-icon {
    background-image: url("../assets/icon/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.create-assign-form {
    .col-form-label {
        min-width: 200px;
    }
}

label.required {
        &:after {
            content: " *";
            color: $danger;
        }
}

.form-control-primary {
    border: 2px solid $primary;
}

.my-assign-item {
    .btn-primary.disabled, .btn-primary:disabled {
        color: #fff;
        background-color: #868686;
        border-color: #868686;
    }
}

@import "styles.variables";
@import "styles.form";
.main-layout {
    &#wrapper {
        overflow-x: hidden;
        min-height: calc(100vh - 4px);
    }

    #sidebar-wrapper {
        //min-height: 100vh;
        //min-height: calc(100vh - 4px);
        min-height: 100%;
        margin-left: -16rem;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;

        .sidebar-heading {
            padding: 0.875rem 1.25rem;
            font-size: 1.2rem;

            background-color: #fff;
            border-radius: 0 0 20px 20px;
        }

        .list-group {
            width: 16rem;
        }
    }


    #page-content-wrapper {
        min-width: 100vw;
    }

    &#wrapper.toggled #sidebar-wrapper {
        margin-left: 0;
        min-height: 100%;
    }

    @media (min-width: 768px) {
        #sidebar-wrapper {
            margin-left: 0;
        }

        #page-content-wrapper {
            min-width: 0;
            width: 100%;
        }

        &#wrapper.toggled #sidebar-wrapper {
            margin-left: -16rem;
        }
    }

    .logo-wide-box {
        transition: opacity .25s ease-in-out;
    }

    .logo-wide-box {
        margin-left: 10px;
        visibility: visible;
        opacity: 1;
    }

    &.toggled {
        .logo-wide-box {
            visibility: hidden;
            opacity: 0;
        }
    }


    @media (min-width: 768px) {
        .logo-wide-box {
            visibility: hidden;
            opacity: 0;
        }

        .hideSidebar .logo-wide-box {
            visibility: visible;
            opacity: 1;
        }

        &.toggled {
            .logo-wide-box {
                margin-left: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    #sidebar-wrapper {
        z-index: 1;
        // border-right: 2px solid #d0d0d0;
    }

    .main-sidebar-menu,
    .main-sidebar-menu-header {
        margin-top: 0.5rem;
        padding: 0 10px;

        .list-group-item {
            color: #696969;
            font-weight: 500;
            border: none;
            line-height: 25px;
            background-color: $light;
            border-radius: 5px;
        }

        .menu-icon {
            margin-right: 0.5rem;
        }
    }

    .main-sidebar-menu-header {
        .list-group-item {
            color: #fff;
            background-color: $primary;
            cursor: pointer;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        }
    }

    .main-sidebar-menu {
        visibility: hidden;
        overflow: hidden;
        max-height: 0;
        opacity: 1;
        transition: all .25s ease-in-out;

        &.show {
            visibility: visible;
            overflow: hidden;
            max-height: 10000px;
            opacity: 1;
        }

        .list-group-item {
            &:hover {
                color: #fff;
                background-color: $primary;

                .menu-icon {
                    filter: invert(1) grayscale(1) brightness(75);
                }
            }
        }

        .active {
            color: $primary;
            background-color: #2A389326;
        }
    }
}

.fixed-video-layout {
    height: calc(100vh - 7px);

    .container-form {
        flex: 1 1 auto !important;
        overflow-y: scroll;
    }
}

.inner-scroll-layout {
    #sidebar-wrapper {
        height: calc(100vh - 4px);
        overflow-y: auto;
    }
    #page-content-wrapper {
        height: calc(100vh - 4px);
    }

    .scrollable {
        overflow-y: auto;
    }
}

.otp-input {
    width: 2.5em;
    text-align: center;
    margin-right: 0.5em;
    padding: 0 !important;

    &.is-invalid {
        background-image: none;
        padding-right: 0.75rem;
    }

    @media (max-width: 991px) {
        & {
            width: 2em;
        }
    }

    @media (max-width: 767px) {
        & {
            width: 1.4em;
        }
    }
}

.otp-form-group {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    align-items: baseline;
}

.upload-user-photo-wrapper {
    width: 135px;
    height: 135px;
    background: #ffffff;
    background-image: url('../assets/icon/user.svg');
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    margin-bottom: 6px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 300px;
    position: relative;
    border: 2px solid #fff;

    .camera-icon {
        background-image: url('../assets/icon/camera2.svg');
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #fileDropRef {
        display: block;
        position: absolute;
        background-color: #e4e4e4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .upload-user-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 500px;
    }
}

.upload-user-photo-field-group {
    margin-top: -120px;

    .upload-user-photo-field {
        margin-left: auto;
    }

    @media (max-width: 767px) {
        & {
            margin-top: 0;

            .upload-user-photo-field {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

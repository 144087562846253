.VideoWrapper {
    position: relative;
    display: none;
    background-color: #1f1f1f;

    &.callStarted {
        display: block;
        height: 100%;
    }

    .Video-control-top {
        position: absolute;
        top: 10px;
        left: 15px;
        z-index: 9;
    }

    .Video-control-bottom {
        position: absolute;
        bottom: 10px;
        width: 100%;
        z-index: 9;
    }

    .Video-control {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-control {
            color: #4e4e4e;
            background-color: rgb(255, 255, 255, 0.5);
            border-color: rgb(255, 255, 255, 0);
            border-radius: 100px;
            min-width: 40px;
            height: 40px;
            text-align: center;
            padding: 0 10px;
            line-height: 40px;
            margin: 0 5px;

            &:hover {
                background-color: #e4e4e4;
            }
        }

        .btn {
            img {
                display: block;
            }
        }

        .btn-phone {
            margin: 0 5px;
            min-width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 100px;
        }
    }

    .Video-control {
        opacity: 0.35;
        transition: opacity .15s ease-out;
    }

    &:hover .Video-control {
        opacity: 1;
    }

    .VideoBoxWrapper {
        position: relative;
        background-color: #1f1f1f;
        justify-content: center;
    }



    &.overlayMode {
        .VideoBox {
            background-color: rgba(228, 228, 228, 0.21);
        }

        .video {
            line-height: 0;
            display: block;
            max-width: 100%;
            padding: 5px;
            width: auto;
        }

        #myVideoBox {
            background-color: rgba(177, 177, 177, 0.4);
        }

        &.not-swap {
            #yourVideoBox {
                .video {
                    height: 100%;

                }
            }

            #myVideoBox {
                position: absolute;
                min-width: 160px;
                width: 15%;
                height: auto;
                right: 10px;
                top: 10px;
                // border: 3px solid #e4e4e4;

                .video {
                    width: 100%;
                }
            }
        }

        &.swap {
            #myVideoBox {
                .video {
                    height: 100%;
                }
            }

            #yourVideoBox {
                position: absolute;
                min-width: 160px;
                width: 15%;
                height: auto;
                right: 10px;
                top: 10px;
                // border: 3px solid #e4e4e4;

                .video {
                    width: 100%;
                }
            }
        }
    }

    &.splitMode {
        .video {
            height: 100%;
        }

        .VideoBox {
            background-color: rgba(228, 228, 228, 0.21);
            height: 100%;
        }

        .video {
            line-height: 0;
            display: block;
            max-width: 100%;
            padding: 5px;
            width: auto;
        }

        #yourVideoBox {
            max-width: 75%;
        }

        #myVideoBox {
            background-color: rgba(177, 177, 177, 0.4);
            max-width: 25%;
        }

    }

    &.splitMode.swap .VideoBoxWrapper {
        flex-direction: row-reverse;


        #yourVideoBox {
            max-width: 25%;
        }

        #myVideoBox {
            background-color: #b1b1b1;
            max-width: 75%;
        }
    }
}

$primary: #2A3893;
$secondary: #868686;
$success: #0EB03B;
$success: #218219;
$danger: #E02A40;
$warning: #FFCB14;
$info: #0097FB;
$light: #F4F4F4;
$dark: #4e4e4e;

.btn-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.btn {
    border-radius: 5px;
}

.btn-warning,
.btn-secondary {
    color: #fff !important;
}

.btn-warning {
    &,
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}

.btn-outline-warning {
    &:hover,
    &:active {
        color: #fff !important;
    }
}

.btn:not(.btn-link),
.btn:not(.btn-link):hover,
.btn:not(.btn-link):focus,
.btn:not(.btn-link):active {
    //color: #fff;// !important;
}

.btn-round {
    border-radius: 50px;
    min-width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    padding: 0;
}



@import "styles.variables";
@import "styles.form";
.call-layout {
    &#wrapper {
        overflow-x: hidden;
    }

    #sidebar-wrapper {
        //min-height: 100vh;
        min-height: calc(100vh - 4px);
        margin-left: -16rem;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;

        .sidebar-heading {
            padding: 0.875rem 1.25rem;
            font-size: 1.2rem;
        }

        .call-layout-sidebar {
            width: 16rem;
        }
    }


    #page-content-wrapper {
        min-width: 100vw;
    }

    &#wrapper.toggled #sidebar-wrapper {
        margin-left: 0;
    }

    @media (min-width: 768px) {
        #sidebar-wrapper {
            margin-left: 0;
        }

        #page-content-wrapper {
            min-width: 0;
            width: 100%;
        }

        &#wrapper.toggled #sidebar-wrapper {
            margin-left: -16rem;
        }
    }

    .logo-wide-box {
        transition: opacity .25s ease-in-out;
    }

    .logo-wide-box {
        margin-left: 10px;
        visibility: visible;
        opacity: 1;
    }

    #sidebar-wrapper {
        z-index: 1;
        border-right: 2px solid #d0d0d0;
    }
}
